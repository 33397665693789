import { useEffect, useState, useContext } from "react";
import { Button, Form, Input, Toast, TextArea, NavBar, Selector} from "antd-mobile";

import { GlobalContext } from "@/App.js";
import { Ajax } from '@/api/http.js';

export default function UserConfig(){
    const {globalData} = useContext(GlobalContext);
    const [form]=Form.useForm();
    const [cityOptions,setCityOptions] = useState([]);

    useEffect(()=>{
        //生成城市列表
        let tmpCityOpts=[];
        for(let city in globalData.siteConfig.citys){
            tmpCityOpts.push({
                label:city,
                value:city
            })
        }
        setCityOptions(tmpCityOpts);

        //获取已有设置
        Ajax('/user/getconfig','post').then(res=>{
            form.setFieldsValue({
                'viewPassword':res['data']['viewPassword'],
                'viewMoney':res['data']['viewMoney'],
                'viewAddress':[res['data']['viewAddress']],
                'viewNumber':[res['data']['viewNumber']],
                'siteName':res['data']['siteName'],
                'siteMessage':res['data']['siteMessage'],
                'defaultCity':[res['data']['defaultCity']]
            });
        });
    },[])

    //提交表单
    const onFinsih=(vals)=>{
        if(vals['defaultCity']){
            vals['defaultCity']=vals['defaultCity'][0];
        }

        if(vals['viewAddress'].length==0){
            vals['viewAddress']=false;
        }else{
            vals['viewAddress']=vals['viewAddress'][0]
        }
        if(vals['viewNumber'].length==0){
            vals['viewNumber']=false;
        }else{
            vals['viewNumber']=vals['viewNumber'][0];
        }
        Ajax('/user/setconfig','post',vals).then(res=>{
            Toast.show({
                icon:'success',
                content:'设置成功！'
            })
        })
    }
    
    return (
        <>
            <NavBar 
                children='客服设置' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{margin:'15px 15px 0px 15px'}}>
                <p style={{fontSize:'15px'}}>
                    此功能供客服使用，您设置好自己专属的邀请码发给大哥，大哥输入这个邀请码打开网站后， 网站会根据您的设置进行显示。
                </p>
                <p style={{color:'red'}}>请注意，退出登陆后以您的邀请码进入后才能看到效果！</p>
            </div>
            <Form
                form={form}
                style={{margin:'30px 0'}}
                footer={
                    <Button block type='submit' color='primary' size='large'>保存设置</Button>
                }
                onFinish={onFinsih}
            >
                <Form.Item label='默认显示的城市' name='defaultCity'>
                    <Selector
                        options={cityOptions}
                    />
                </Form.Item>
                <Form.Item name='viewPassword' label='邀请码(6位数,不能都是数字)'>
                    <Input placeholder="6位以上字母和数字"></Input>
                </Form.Item>
                <Form.Item name='viewMoney' label='自动加价金额(若填写必须大于600)'>
                    <Input placeholder="比如800，如果不填则不显示价格"></Input>
                </Form.Item>
                <Form.Item name='viewAddress' label='妹子地址'>
                    <Selector
                        options={[
                            {label:'显示地址',value:true},
                            {label:'不显示地址',value:false}
                        ]}
                    />
                </Form.Item>
                <Form.Item name='viewNumber' label='妹子名称'>
                    <Selector 
                        options={[
                            {label:'显示名称',value:false},
                            {label:'只显示编号',value:true}
                        ]}
                    />
                </Form.Item>
                <Form.Item name='siteName' label='网站名称'>
                    <Input placeholder="网站的名称和标题"></Input>
                </Form.Item>
                <Form.Item name='siteMessage' label='访客通知'>
                    <TextArea placeholder="给访客们的通知内容，将会在首页弹出" autoSize={true} rows={4}></TextArea>
                </Form.Item>
            </Form>
        </>
    )
}
