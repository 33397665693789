import { useEffect, useState, useContext } from "react";
import { Form, Input, Button, NavBar, Selector, Switch, TextArea} from 'antd-mobile'
import { Ajax } from '@/api/http.js';

import { GlobalContext } from "@/App.js";

export default function User(props){
    const {globalData} = useContext(GlobalContext);

    const [form]=Form.useForm();
    const [title,setTitle]=useState('');
    const [cityOptions,setCityOptions] = useState([]);

    //生成城市选项列表
    useEffect(()=>{
        let tmpCityOpts=[];
        for(let city in globalData.siteConfig.citys){
            tmpCityOpts.push({
                label:city,
                value:city
            })
        }
        setCityOptions(tmpCityOpts);
    },[])

    //加载用户数据
    useEffect(()=>{
        if(props.id){
            Ajax('/user/get','post',{'id':props.id}).then(res=>{
                setTitle(res['data']['name']);

                form.setFieldsValue({
                    'category':[res['data']['category']],
                    'name':res['data']['name'],
                    'password':'',

                    'viewPassword':res['data']['viewPassword'],
                    'viewMoney':res['data']['viewMoney'],
                    'viewAddress':res['data']['viewAddress'],
                    'viewNumber':res['data']['viewNumber'],
                    'siteName':res['data']['siteName'],
                    'siteMessage':res['data']['siteMessage'],
                    'defaultCity':[res['data']['defaultCity']],
                    'allowComment':res['data']['allowComment'],

                    'maxWorkGirl':res['data']['maxWorkGirl'],
                })
            })
        }else{
            setTitle('新的用户');
            form.resetFields();
        }
    },[props.id])

    //提交表单
    function onFinish(vals){
        
        let url='/user/add';
        if(props.id){
            url='/user/edit';
            vals['id']=props.id;
        }

        vals['category']=vals['category'][0];
        if(vals['defaultCity']){
            vals['defaultCity']=vals['defaultCity'][0];
        }

        Ajax(url,'post',vals).then(res=>{
            if(!props.id){
                form.resetFields();
            }
            props.onClose();
            props.onReload();
        })
    }

    return (
        <>
            <NavBar
                children={title}
                onBack={props.onClose}
                style={{position:'sticky',top:'0',zIndex:9999,height:'50px',backgroundColor:'#efefef'}}
                right={
                    <Button color='success' size='small' onClick={()=>form.submit()}>保存</Button>
                }
            />
            <Form
                form={form}
                mode="card"
                onFinish={onFinish}
                style={{margin:'0 0 130px 0'}}
            >
                <Form.Item label='类别' name='category' rules={[{ required: true }]}>
                    <Selector
                        options={[
                            {label:'系统管理员',value:0},
                            {label:'会员',value:1}
                        ]}
                    />
                </Form.Item>
                <Form.Item label='用户名' name='name' rules={[{ required: true }]}>
                    <Input placeholder="请输入用户名称"></Input>
                </Form.Item>
                <Form.Item label='密码' name='password'>
                    <Input placeholder="老用户可以不必输入"></Input>
                </Form.Item>
                
                <Form.Item label='邀请码' name='viewPassword'>
                    <Input placeholder=""></Input>
                </Form.Item>
                <Form.Item label='默认显示的城市' name='defaultCity'>
                    <Selector
                        options={cityOptions}
                    />
                </Form.Item>
                <Form.Item label='加价金额' name='viewMoney'>
                    <Input placeholder=""></Input>
                </Form.Item>
                <Form.Item label='显示地址' name='viewAddress' valuePropName="checked">
                    <Switch checkedText='显示' uncheckedText='隐藏'></Switch>
                </Form.Item>
                <Form.Item label='显示编号' name='viewNumber' valuePropName="checked">
                    <Switch checkedText='显示编号' uncheckedText='显示名称' />
                </Form.Item>
                <Form.Item label='是否允许评价' name='allowComment' valuePropName="checked">
                    <Switch checkedText='允许' uncheckedText='不允许'></Switch>
                </Form.Item>
                <Form.Item label='网站名称' name='siteName'>
                    <Input placeholder=""></Input>
                </Form.Item>
                <Form.Item label='访客通知' name='siteMessage'>
                    <TextArea placeholder="给访客看的通知内容" rows={6}></TextArea>
                </Form.Item>
                <Form.Item label='最大在线妹子数量' name='maxWorkGirl'>
                    <Input placeholder="不限制就不要填"></Input>
                </Form.Item>
            </Form>
        </>
    )
}
